:root {
    --primary-color: #5eead4;
    --primary-dark: #4fd1c5;
    --background-color: #0a0f1a;
    --text-color: #ffffff;
    --text-secondary: #94a3b8;
}

.sidebarHoverTrigger {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    z-index: 1001;
}

.sidebar {
    width: 400px;
    background-color: var(--background-color);
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.sidebar.open {
    transform: translateX(0);
}

.sidebarHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebarTitle {
    font-size: 2.5rem;
    font-weight: bold;
    margin: 0;
    color: var(--primary-color);
    background: linear-gradient(45deg, #5eead4, #4fd1c5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.pinButton,
.clearButton {
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    transition: transform 0.3s ease;
    padding: 5px;
}

.pinButton:hover,
.clearButton:hover {
    transform: scale(1.1);
}

.chatHistory {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px 30px;
}

.message {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 15px;
    border-radius: 12px;
    max-width: 90%;
    animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.userMessage {
    background-color: rgba(94, 234, 212, 0.1);
    margin-left: auto;
}

.aiMessage {
    background-color: rgba(255, 255, 255, 0.05);
}

.loadingMessage {
    background-color: transparent;
}

.messageIcon {
    margin-right: 15px;
    background-color: var(--primary-color);
    border-radius: 50%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message p {
    margin: 0;
    color: var(--text-color);
    line-height: 1.5;
    font-size: 0.95rem;
}

.typingIndicator {
    display: flex;
    align-items: center;
}

.typingIndicator span {
    height: 8px;
    width: 8px;
    background-color: var(--primary-color);
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
    animation: typing 1s infinite ease-in-out;
}

.typingIndicator span:nth-child(2) {
    animation-delay: 0.2s;
}

.typingIndicator span:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes typing {
    0% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0); }
}

.audioControls {
    display: flex;
    justify-content: center;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.05);
}

.iconButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 12px;
    border-radius: 50%;
    transition: all 0.3s ease;
    color: var(--primary-color);
    margin: 0 15px;
}

.iconButton:hover {
    background-color: rgba(94, 234, 212, 0.1);
}

.iconButton.recording {
    color: #ff4444;
}

.iconButton.playing {
    color: #44ff44;
}

.chatInputContainer {
    padding: 25px;
    display: flex;
    background-color: rgba(255, 255, 255, 0.05);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.chatInput {
    flex-grow: 1;
    padding: 12px 20px;
    border: 2px solid var(--primary-color);
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--text-color);
    font-size: 1rem;
    transition: all 0.3s ease;
}

.chatInput:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(94, 234, 212, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
}

.sendButton {
    background-color: var(--primary-color);
    color: var(--background-color);
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.sendButton:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.sendButton:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
    }

    .chatHistory {
        padding: 15px;
    }

    .message {
        max-width: 100%;
    }

    .chatInputContainer {
        padding: 15px;
    }

    .chatInput {
        font-size: 0.9rem;
    }

    .sendButton {
        width: 40px;
        height: 40px;
    }
}