:root {
  --primary-color: #5eead4;
  --primary-dark: #4fd1c5;
  --background-color: #0a0f1a;
  --text-color: #ffffff;
  --text-secondary: #94a3b8;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', 'Arial', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

.homeContainer {
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(10, 15, 26, 0.8);
  backdrop-filter: blur(10px);
  z-index: 1000;
}

.logo {
  font-size: 3rem;
  font-weight: bold;
  color: var(--primary-color);
  text-decoration: none;
  transition: transform 0.3s ease, text-shadow 0.3s ease;
  text-shadow: 0 0 10px rgba(94, 234, 212, 0.3);
}

.logo:hover {
  transform: scale(1.05);
  text-shadow: 0 0 15px rgba(94, 234, 212, 0.5);
}

.nav {
  display: flex;
  gap: 2.5rem;
}

.nav a {
  color: var(--text-color);
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  font-size: 1.1rem;
}

.nav a:hover {
  color: var(--primary-color);
}

.ctaButton {
  background-color: var(--primary-color);
  color: var(--background-color) !important;
  padding: 0.7rem 1.5rem;
  border-radius: 9999px;
  font-weight: 600;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.ctaButton:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

/* Main Content Styles */
.mainContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 6rem 2rem 4rem;
}

/* Hero Section Styles */
.heroSection {
  text-align: center;
  padding: 8rem 0 6rem;
}

.title {
  font-size: 7rem;
  font-weight: 700;
  background: linear-gradient(45deg, #5eead4, #4fd1c5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 2rem;
  text-shadow: 0 0 20px rgba(94, 234, 212, 0.3);
}

.tagline {
  font-size: 2.5rem;
  line-height: 1.4;
  color: var(--text-color);
  max-width: 800px;
  margin: 0 auto 1rem;
}

.subheadline {
  font-size: 1.5rem;
  line-height: 1.6;
  color: var(--text-secondary);
  max-width: 800px;
  margin: 0 auto 4rem;
}

.heroCtaButton {
  display: inline-flex;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--background-color);
  padding: 1.2rem 2.5rem;
  border-radius: 9999px;
  font-size: 1.3rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
}

.heroCtaButton:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 4px 6px rgba(94, 234, 212, 0.1);
}

.heroCtaButton svg {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.heroCtaButton:hover svg {
  transform: translateX(4px);
}

/* Section Styles */
.section {
  margin-top: 8rem;
  padding: 4rem 0;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sectionTitle {
  font-size: 3.5rem;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
  background: linear-gradient(45deg, #5eead4, #4fd1c5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(94, 234, 212, 0.3);
}

/* About Section Styles */
.aboutText {
  font-size: 1.2rem;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  color: var(--text-secondary);
}

/* Features Section Styles */
.featuresGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.featureCard {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
}

.featureCard:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-5px);
}

.featureIcon {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.featureCard h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.featureCard p {
  font-size: 1rem;
  color: var(--text-secondary);
}

/* Benefits Section Styles */
.benefitsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-top: 3rem;
}

.benefitCard {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
}

.benefitCard:hover {
  background: rgba(255, 255, 255, 0.1);
  transform: translateY(-5px);
}

.benefitIcon {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.benefitCard h3 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.benefitCard p {
  font-size: 1rem;
  color: var(--text-secondary);
}

/* Demo Section Styles */
.demoContainer {
  max-width: 800px;
  margin: 0 auto;
}

.demoPlaceholder {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: var(--text-secondary);
}

/* Footer Styles */
.footer {
  background-color: rgba(30, 41, 59, 0.7);
  backdrop-filter: blur(10px);
  padding: 4rem 2rem 2rem;
  margin-top: 6rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footerContent {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footerSection {
  flex: 1;
  margin-right: 2rem;
}

.footerSection h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.footerSection p {
  color: var(--text-secondary);
  margin-bottom: 1rem;
}

.footerSection ul {
  list-style-type: none;
  padding: 0;
}

.footerSection ul li {
  margin-bottom: 0.5rem;
}

.footerSection a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footerSection a:hover {
  color: var(--primary-color);
}

.copyright {
  text-align: center;
  margin-top: 3rem;
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.scrollTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--primary-color);
  color: var(--background-color);
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(94, 234, 212, 0.3);
  z-index: 1000;
}

.scrollTopButton:hover {
  background-color: var(--primary-dark);
  transform: translateY(-2px);
}

/* Utility Styles */
.loadingSpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 1.2rem;
  color: var(--primary-color);
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .mainContent {
    padding: 6rem 1rem 4rem;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem;
  }

  .nav {
    margin-top: 1rem;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .heroSection {
    padding: 6rem 0 4rem;
  }

  .title {
    font-size: 4.5rem;
  }

  .tagline {
    font-size: 2rem;
  }

  .subheadline {
    font-size: 1.2rem;
  }

  .heroCtaButton {
    padding: 1rem 2rem;
    font-size: 1.1rem;
  }

  .section {
    margin-top: 4rem;
    padding: 2rem 1rem;
  }

  .sectionTitle {
    font-size: 2.5rem;
  }

  .aboutText {
    font-size: 1rem;
  }

  .featuresGrid,
  .benefitsGrid {
    grid-template-columns: 1fr;
  }

  .footerContent {
    flex-direction: column;
  }

  .footerSection {
    margin-right: 0;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 3.5rem;
  }

  .tagline {
    font-size: 1.5rem;
  }

  .subheadline {
    font-size: 1rem;
  }

  .heroCtaButton {
    padding: 0.8rem 1.6rem;
    font-size: 1rem;
  }

  .sectionTitle {
    font-size: 2rem;
  }

  .featureCard,
  .benefitCard {
    padding: 1.5rem;
  }
}