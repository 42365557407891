:root {
    --primary-color: #5eead4;
    --primary-dark: #4fd1c5;
    --background-color: #0f172a; /* Updated dark blue-gray */
    --secondary-background: #1e293b; /* Slightly lighter for contrast */
    --text-color: #ffffff;
    --text-secondary: #94a3b8;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Inter', 'Arial', sans-serif;
    background-color: var(--background-color);
    color: var(--text-color);
    line-height: 1.6;
}

.chat-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    position: relative;
    overflow: hidden;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: var(--background-color);
    color: var(--text-color);
    width: 100%;
    overflow-y: auto;
}

.table-header {
    background-color: var(--secondary-background);
    color: var(--text-color);
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header-left {
    display: flex;
    align-items: center;
}

.logo {
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: bold;
    margin-right: 1rem;
    text-decoration: none;
}

.table-header h2 {
    margin: 0;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
}

.header-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.button-group {
    display: flex;
    gap: 10px;
}

.refresh-button,
.booster-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    color: var(--background-color);
    background-color: var(--primary-color);
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.refresh-button:hover:not(:disabled),
.booster-button:hover:not(:disabled) {
    background-color: var(--primary-dark);
}

.refresh-button:disabled,
.booster-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.refresh-button .spin {
    animation: spin 1s linear infinite;
}

.booster-button .pulse {
    animation: pulse 1s ease-in-out infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    background-color: var(--secondary-background);
    color: var(--text-color);
}

.table-footer span {
    margin-right: 20px;
}

.pagination-controls button {
    background: none;
    border: none;
    color: var(--text-color);
    font-size: 18px;
    cursor: pointer;
    padding: 0 8px;
}

.pagination-controls button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 1200px) {
    /* Add any responsive styles for the overall layout if needed */
}

@media (max-width: 992px) {
    /* Add any responsive styles for the overall layout if needed */
}

@media (max-width: 768px) {
    .table-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .button-group {
        margin-top: 10px;
    }
}


/* AUg 21 Save

.chat-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    position: relative;
    overflow: hidden;
}

.sidebar-hover-trigger {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    z-index: 1001;
}

.sidebar {
    width: 300px;
    background-color: #242321;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    left: -300px;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
}

.sidebar.open {
    left: 0;
}

.pin-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
    background-color: #1e1e1e;
    color: #ffffff;
    width: 100%;
}

.main-content.sidebar-open {
    margin-left: 300px;
    width: calc(100% - 300px);
}

h2 {
    padding: 20px;
    margin: 0;
    background-color: #242321;
}

.chat-history {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    max-width: 80%;
}

.user-message {
    background-color: #2c2c2c;
    align-self: flex-end;
    margin-left: auto;
}

.ai-message {
    background-color: #3c3c3c;
}

.message a {
    color: #4CAF50;
    text-decoration: none;
}

.message a:hover {
    text-decoration: underline;
}

.audio-controls {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background-color: #242321;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
    color: #ffffff;
}

.icon-button:hover {
    background-color: #3c3c3c;
}

.icon-button.recording {
    color: #ff4444;
}

.icon-button.playing {
    color: #44ff44;
}

.chat-input-container {
    padding: 20px;
    background-color: #242321;
}

.chat-input-container form {
    display: flex;
}

.chat-input-container input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #3c3c3c;
    border-radius: 4px 0 0 4px;
    background-color: #1e1e1e;
    color: #ffffff;
}

.chat-input-container button {
    padding: 10px 20px;
    background-color: #3c3c3c;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.chat-input-container button:hover {
    background-color: #4c4c4c;
}

.table-header {
    background-color: #153064;
    color: #ffffff;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.table-header h2 {
    margin: 0;
    font-size: 1.5rem;
    background-color: #153064;
    display: flex;
    align-items: center;
}

.header-icon {
    margin-left: 10px;
    width: 24px;
    height: 24px;
    object-fit: contain;
}

.button-group {
    display: flex;
    gap: 10px;
}

.refresh-button,
.booster-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.refresh-button {
    background-color: #4CAF50;
}

.refresh-button:hover {
    background-color: #45a049;
}

.booster-button {
    background-color: #2196F3;
}

.booster-button:hover {
    background-color: #1e87db;
}

.refresh-button:disabled,
.booster-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.refresh-button .spin {
    animation: spin 1s linear infinite;
}

.booster-button .pulse {
    animation: pulse 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    table-layout: fixed;
}

.data-table th,
.data-table td {
    padding: 12px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.data-table th {
    background-color: #2c2c2c;
    color: #ffffff;
    font-weight: normal;
}

.data-table tbody tr {
    background-color: #2c2c2c;
}

.data-table tbody tr:hover {
    background-color: #3c3c3c;
}

.data-table th:nth-child(1),
.data-table td:nth-child(1) {
    width: 50px;
}

.data-table th:nth-child(2),
.data-table td:nth-child(2) {
    width: 60px;
}

.data-table th:nth-child(3),
.data-table td:nth-child(3) {
    width: 150px;
}

.data-table th:nth-child(4),
.data-table td:nth-child(4) {
    width: auto;
}

.data-table th:nth-child(5),
.data-table td:nth-child(5) {
    width: 150px;
}

.data-table th:nth-child(6),
.data-table td:nth-child(6) {
    width: 100px;
}

.data-table th:nth-child(7),
.data-table td:nth-child(7) {
    width: 150px;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: bold;
}

.table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    background-color: #2c2c2c;
    color: #ffffff;
}

.table-footer span {
    margin-right: 20px;
}

.pagination-controls button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    padding: 0 8px;
}

.pagination-controls button:hover {
    background-color: #3c3c3c;
}

.email-editor {
    margin-top: 20px;
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 4px;
}

.email-editor h3 {
    color: #ffffff;
    margin-bottom: 10px;
}


.ql-toolbar.ql-snow {
    border: 1px solid #3c3c3c;
    background-color: #242321;
}

.ql-container.ql-snow {
    border: 1px solid #3c3c3c;
    background-color: #1e1e1e;
    color: #ffffff;
    height: 200px;
}

.ql-editor p {
    color: #ffffff;
}

.ql-snow .ql-stroke {
    stroke: #ffffff;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: #ffffff;
}

.ql-snow .ql-picker {
    color: #ffffff;
}

.ql-snow .ql-picker-options {
    background-color: #2c2c2c;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #cccccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #3c3c3c;
}

.ticket-detail .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ticket-detail .button-group {
    display: flex;
    gap: 10px;
}

.ticket-conversations {
    max-height: calc(50vh - 60px);
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.message {
    max-width: 80%;
    padding: 10px 15px;
    border-radius: 18px;
    margin-bottom: 10px;
    word-wrap: break-word;
    line-height: 1.4;
    position: relative;
}

.customer {
    background-color: #2d3d58;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 4px;
}

.agent {
    background-color: #2C2C2C;
    color: white;
    align-self: flex-start;
    border-bottom-left-radius: 4px;
}

.message-time {
    font-size: 0.75em;
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: -18px;
    right: 10px;
}

.customer .message-time {
    right: 10px;
}

.agent .message-time {
    left: 10px;
}

.expanded-row {
    background-color: #1e1e1e;
  }

.ticket-details {
    max-height: 400px; 
    overflow-y: auto;
    padding: 20px;
  }
  

.conversation-container {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 20px;
}

.ticket-comments {
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
}

.comment {
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.4;
}


.comment.customer {
    background-color: #2d3d58;
    margin-right: 20px;
  }
  
  .comment.agent {
    background-color: #3c3c3c;
    margin-left: 20px;
  }
  
  .comment-time {
    font-size: 12px;
    color: #888;
    text-align: right;
    margin-top: 5px;
  }
  
  .comment-sender {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .intelligent-ai-email {
    margin-top: 20px;
  }

.intelligent-ai-email h4 {
    margin-bottom: 10px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.tag {
    background-color: #4a4a4a;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8em;
    display: inline-block;

}

.no-tags {
    color: #888;
    font-style: italic;
}

.ticket-subject {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #333;
    border-radius: 5px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #45a049;
}


.status-box {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.85em;
    font-weight: 500;
    text-align: center;
    display: inline-block;
    min-width: 80px;
    text-transform: capitalize;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .status-open {
    color: #3b82f6;
  }
  
  .status-solved, .status-closed {
    color: #22c55e;
  }
  
  .status-hold {
    color: #eab308;
  }

.data-table th:nth-child(2),
.data-table td:nth-child(2) {
    width: 100px;
}

.data-table th:nth-child(6),
.data-table td:nth-child(6) {
    width: 150px;
}

.data-table th:nth-child(7),
.data-table td:nth-child(7) {
    width: 100px;
}

.data-table th:nth-child(8),
.data-table td:nth-child(8) {
    width: 150px;
}

@media (max-width: 1200px) {

    .main-content.sidebar-open .data-table th:nth-child(8),
    .main-content.sidebar-open .data-table td:nth-child(8) {
        display: none;
    }
}

@media (max-width: 992px) {

    .main-content.sidebar-open .data-table th:nth-child(7),
    .main-content.sidebar-open .data-table td:nth-child(7) {
        display: none;
    }
}
*/




/*Aug 6 12:30pm Save
.chat-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
    position: relative;
    overflow: hidden;
}

.sidebar-hover-trigger {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 100%;
    z-index: 1001;
}

.sidebar {
    width: 300px;
    background-color: #242321;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: fixed;
    left: -300px;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
}

.sidebar.open {
    left: 0;
}

.pin-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: #ffffff;
    cursor: pointer;
}

.main-content {
    flex-grow: 1;
    padding: 20px;
    margin-left: 0;
    transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
    background-color: #1e1e1e;
    color: #ffffff;
    width: 100%;
}

.main-content.sidebar-open {
    margin-left: 300px;
    width: calc(100% - 300px);
}

h2 {
    padding: 20px;
    margin: 0;
    background-color: #242321;
}

.chat-history {
    flex-grow: 1;
    overflow-y: auto;
    padding: 20px;
}

.message {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    max-width: 80%;
}

.user-message {
    background-color: #2c2c2c;
    align-self: flex-end;
    margin-left: auto;
}

.ai-message {
    background-color: #3c3c3c;
}

.message a {
    color: #4CAF50;
    text-decoration: none;
}

.message a:hover {
    text-decoration: underline;
}

.audio-controls {
    display: flex;
    justify-content: space-around;
    padding: 10px;
    background-color: #242321;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    transition: background-color 0.3s;
    color: #ffffff;
}

.icon-button:hover {
    background-color: #3c3c3c;
}

.icon-button.recording {
    color: #ff4444;
}

.icon-button.playing {
    color: #44ff44;
}

.chat-input-container {
    padding: 20px;
    background-color: #242321;
}

.chat-input-container form {
    display: flex;
}

.chat-input-container input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #3c3c3c;
    border-radius: 4px 0 0 4px;
    background-color: #1e1e1e;
    color: #ffffff;
}

.chat-input-container button {
    padding: 10px 20px;
    background-color: #3c3c3c;
    color: white;
    border: none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.chat-input-container button:hover {
    background-color: #4c4c4c;
}

.table-header {
    background-color: #153064; 
    color: #ffffff; 
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.table-header h2 {
    margin: 0;
    font-size: 1.5rem;
    background-color: #153064;
    display: flex;
    align-items: center;
}

.header-icon {
    margin-left: 10px;
    width: 24px;  
    height: 24px; 
    object-fit: contain;
  }

.button-group {
    display: flex;
    gap: 10px;
}

.refresh-button,
.booster-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.refresh-button {
    background-color: #4CAF50;
}

.refresh-button:hover {
    background-color: #45a049;
}

.booster-button {
    background-color: #2196F3;
}

.booster-button:hover {
    background-color: #1e87db;
}

.refresh-button:disabled,
.booster-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.refresh-button .spin {
    animation: spin 1s linear infinite;
}

.booster-button .pulse {
    animation: pulse 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.data-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 4px;
    table-layout: fixed;
}

.data-table th,
.data-table td {
    padding: 12px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.data-table th {
    background-color: #2c2c2c;
    color: #ffffff;
    font-weight: normal;
}

.data-table tbody tr {
    background-color: #2c2c2c;
}

.data-table tbody tr:hover {
    background-color: #3c3c3c;
}

.data-table th:nth-child(1),
.data-table td:nth-child(1) {
    width: 50px;
}

.data-table th:nth-child(2),
.data-table td:nth-child(2) {
    width: 60px;
}

.data-table th:nth-child(3),
.data-table td:nth-child(3) {
    width: 150px;
}

.data-table th:nth-child(4),
.data-table td:nth-child(4) {
    width: auto;
}

.data-table th:nth-child(5),
.data-table td:nth-child(5) {
    width: 150px;
}

.data-table th:nth-child(6),
.data-table td:nth-child(6) {
    width: 100px;
}

.data-table th:nth-child(7),
.data-table td:nth-child(7) {
    width: 150px;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: bold;
}

.table-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 12px;
    background-color: #2c2c2c;
    color: #ffffff;
}

.table-footer span {
    margin-right: 20px;
}

.pagination-controls button {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 18px;
    cursor: pointer;
    padding: 0 8px;
}

.pagination-controls button:hover {
    background-color: #3c3c3c;
}

.email-editor {
    margin-top: 20px;
    background-color: #2c2c2c;
    padding: 20px;
    border-radius: 4px;
}

.email-editor h3 {
    color: #ffffff;
    margin-bottom: 10px;
}


.ql-toolbar.ql-snow {
    border: 1px solid #3c3c3c;
    background-color: #242321;
}

.ql-container.ql-snow {
    border: 1px solid #3c3c3c;
    background-color: #1e1e1e;
    color: #ffffff;
    height: 200px;
}

.ql-editor p {
    color: #ffffff;
}

.ql-snow .ql-stroke {
    stroke: #ffffff;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
    fill: #ffffff;
}

.ql-snow .ql-picker {
    color: #ffffff;
}

.ql-snow .ql-picker-options {
    background-color: #2c2c2c;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #cccccc;
}

.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #3c3c3c;
}

.ticket-detail .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ticket-detail .button-group {
    display: flex;
    gap: 10px;
}

.ticket-conversations {
    max-height: calc(50vh - 60px);
    overflow-y: auto;
    padding: 15px;
    display: flex;
    flex-direction: column;
}

.message {
    max-width: 80%;
    padding: 10px 15px;
    border-radius: 18px;
    margin-bottom: 10px;
    word-wrap: break-word;
    line-height: 1.4;
    position: relative;
}

.customer {
    background-color: #2d3d58;
    color: white;
    align-self: flex-end;
    border-bottom-right-radius: 4px;
}

.agent {
    background-color: #2C2C2C;
    color: white;
    align-self: flex-start;
    border-bottom-left-radius: 4px;
}

.message-time {
    font-size: 0.75em;
    color: rgba(255, 255, 255, 0.7);
    position: absolute;
    bottom: -18px;
    right: 10px;
}

.customer .message-time {
    right: 10px;
}

.agent .message-time {
    left: 10px;
}

.tag-container {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.tag {
    background-color: #4a4a4a;
    color: white;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 0.8em;
    display: inline-block;
   
}

.no-tags {
    color: #888;
    font-style: italic;
}

.ticket-subject {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 15px;
    padding: 10px;
    background-color: #333;
    border-radius: 5px;
}

.back-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    color: white;
    background-color: #4CAF50;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.back-button:hover {
    background-color: #45a049;
}

.data-table th:nth-child(2),
.data-table td:nth-child(2) {
    width: 100px;
}

.data-table th:nth-child(6),
.data-table td:nth-child(6) {
    width: 150px;
}

.data-table th:nth-child(7),
.data-table td:nth-child(7) {
    width: 100px;
}

.data-table th:nth-child(8),
.data-table td:nth-child(8) {
    width: 150px;
}

@media (max-width: 1200px) {

    .main-content.sidebar-open .data-table th:nth-child(8),
    .main-content.sidebar-open .data-table td:nth-child(8) {
        display: none;
    }
}

@media (max-width: 992px) {

    .main-content.sidebar-open .data-table th:nth-child(7),
    .main-content.sidebar-open .data-table td:nth-child(7) {
        display: none;
    }
}

*/

/* July 31 saved 
.chatgpt-container {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
}

.sidebar {
    width: 25%;
    background-color: #f1f1f1;
    padding: 20px;
    overflow-y: scroll;
}

.sidebar h2 {
    font-size: 1.5em;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sidebar ul li p {
    margin: 5px 0;
}

.main {
    width: 75%;
    padding: 20px;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
}

.input {
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background: #007BFF;
    color: #fff;
    cursor: pointer;
}

.customer-info {
    text-align: left;
    width: 100%;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.ai-response {
    text-align: left;
    width: 100%;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    overflow-wrap: break-word;  
}

.ai-response pre {
    white-space: pre-wrap;  
    word-wrap: break-word;  
}

.ai-response audio {
    width: 100%;
    margin-top: 10px;
}



.ticket-info, .search-results {
    text-align: left;
    width: 100%;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.ticket-info h2, .search-results h2 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.search-results ul {
    list-style-type: none;
    padding: 0;
}

.search-results ul li {
    margin-bottom: 10px;
    background: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
*/