.tableContainer {
    background-color: var(--secondary-background);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
}

.dataTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: var(--text-color);
}

.dataTable th,
.dataTable td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.stickyHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--secondary-background);
}

.dataTable th {
    background-color: var(--secondary-background);
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
}

.sortableHeader {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
}

.sortableHeader:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.dataRow {
    cursor: pointer;
    transition: all 0.3s ease;
}

.evenRow {
    background-color: rgba(255, 255, 255, 0.02);
}

.oddRow {
    background-color: transparent;
}

.dataRow:hover {
    background-color: rgba(255, 255, 255, 0.05);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--text-color);
    text-transform: uppercase;
    font-size: 0.875rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statusBadge {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    display: inline-flex;
    align-items: center;
    min-width: 80px;
    text-transform: capitalize;
}

.statusIcon {
    margin-right: 4px;
}

.statusOpen { 
    background-color: rgba(59, 130, 246, 0.2);
    color: #60a5fa; 
}

.statusSolved,
.statusClosed { 
    background-color: rgba(34, 197, 94, 0.2);
    color: #4ade80; 
}

.statusHold { 
    background-color: rgba(234, 179, 8, 0.2);
    color: #fde047; 
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.tag {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--text-color);
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    white-space: nowrap;
}

.sentimentIndicator {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.expandedRow {
    background-color: var(--background-color);
}

.expandedContent {
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 4px 6px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.ticketSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ticketSummary h3 {
    font-size: 1.2rem;
    color: var(--text-color);
    margin: 0;
}

.quickActions {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.actionButton {
    padding: 8px 16px;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.actionButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.actionButton:active {
    transform: translateY(0);
    box-shadow: none;
}

.conversationContainer {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.conversationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.conversationHeader h4 {
    margin: 0;
    color: var(--text-color);
}

.viewToggle {
    background: none;
    border: none;
    color: var(--primary-color);
    cursor: pointer;
    transition: all 0.2s ease;
}

.viewToggle:hover {
    transform: translateY(-2px);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.message {
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 8px;
    font-size: 0.9rem;
    line-height: 1.5;
    transition: all 0.3s ease;
}

.message.customer {
    background-color: rgba(59, 130, 246, 0.1);
    margin-right: 20px;
    border-left: 3px solid #60a5fa;
}

.message.agent {
    background-color: rgba(34, 197, 94, 0.1);
    margin-left: 20px;
    border-right: 3px solid #4ade80;
}

.message.compact {
    padding: 8px;
    margin-bottom: 8px;
}

.messageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.messageSender {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: var(--text-color);
}

.messageSender svg {
    margin-right: 5px;
}

.messageTime {
    font-size: 0.75rem;
    color: var(--text-secondary);
}

.messageContent {
    color: var(--text-color);
}

.attachments {
    margin-top: 10px;
}

.attachment {
    display: inline-flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--text-color);
    padding: 4px 8px;
    border-radius: 4px;
    margin-right: 8px;
    font-size: 0.8rem;
    text-decoration: none;
    transition: background-color 0.2s ease;
}

.attachment:hover {
    background-color: rgba(255, 255, 255, 0.2);
}

.attachment svg {
    margin-right: 4px;
}

.replyContainer {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-radius: 8px;
}

.replyContainer h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: var(--text-color);
}

.replyActions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.attachButton,
.sendReplyButton {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    transition: all 0.2s ease;
}

.attachButton {
    background-color: rgba(255, 255, 255, 0.1);
    color: var(--text-color);
}

.attachButton:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.sendReplyButton {
    background-color: var(--primary-color);
    color: var(--background-color);
}

.sendReplyButton:hover {
    background-color: var(--primary-dark);
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.attachButton:active,
.sendReplyButton:active {
    transform: translateY(0);
    box-shadow: none;
}

.attachButton svg,
.sendReplyButton svg {
    margin-right: 8px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: var(--secondary-background);
}

.pagination button {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: var(--text-color);
    padding: 8px 16px;
    margin: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.pagination button:hover:not(:disabled) {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.pagination button:active:not(:disabled) {
    transform: translateY(0);
    box-shadow: none;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination span {
    color: var(--text-color);
    font-size: 0.875rem;
}

.pagination button svg {
    margin: 0 4px;
}

.loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.loadingSpinner {
    width: 50px;
    height: 50px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: var(--primary-color);
    animation: spin 1s ease-in-out infinite;
    margin-bottom: 10px;
}

@keyframes spin {
    to { transform: rotate(360deg); }
}

/* ReactQuill Styles */
:global(.ql-toolbar.ql-snow) {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.05);
}

:global(.ql-container.ql-snow) {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background-color: rgba(0, 0, 0, 0.2);
    color: var(--text-color);
    height: 150px;
}

:global(.ql-editor p) {
    color: var(--text-color);
}

:global(.ql-snow .ql-stroke) {
    stroke: var(--text-secondary);
}

:global(.ql-snow .ql-fill),
:global(.ql-snow .ql-stroke.ql-fill) {
    fill: var(--text-secondary);
}

:global(.ql-snow .ql-picker) {
    color: var(--text-secondary);
}

:global(.ql-snow .ql-picker-options) {
    background-color: var(--secondary-background);
}

:global(.ql-snow .ql-picker.ql-expanded .ql-picker-label) {
    color: var(--text-color);
}

:global(.ql-snow .ql-picker.ql-expanded .ql-picker-options) {
    border-color: rgba(255, 255, 255, 0.1);
}

/* Responsive Styles */
@media (max-width: 1200px) {
    .dataTable th:nth-child(9),
    .dataTable td:nth-child(9) {
        display: none;
    }
}

@media (max-width: 992px) {
    .dataTable th:nth-child(7),
    .dataTable td:nth-child(7) {
        display: none;
    }
}

@media (max-width: 768px) {
    .dataTable th,
    .dataTable td {
        padding: 12px 8px;
    }

    .dataTable th {
        font-size: 0.7rem;
    }

    .tag,
    .statusBadge,
    .sentimentIndicator {
        font-size: 0.7rem;
    }

    .avatar {
        width: 30px;
        height: 30px;
        font-size: 0.75rem;
    }

    .quickActions {
        flex-wrap: wrap;
    }

    .actionButton {
        flex: 1 0 calc(50% - 5px);
    }

    .replyActions {
        flex-direction: column;
        gap: 10px;
    }

    .attachButton,
    .sendReplyButton {
        width: 100%;
    }
}



/* Aug 21 Diff design before we updated the expanding ticket section
.tableContainer {
    background-color: #1a1a1a;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-family: 'Inter', 'Helvetica', 'Arial', sans-serif;
}

.dataTable {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    color: #ffffff;
}

.dataTable th,
.dataTable td {
    padding: 16px;
    text-align: left;
    border-bottom: 1px solid #333;
}

.stickyHeader {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #1a1a1a;
}

.dataTable th {
    background-color: #2c2c2c;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.05em;
}

.sortableHeader {
    cursor: pointer;
    user-select: none;
    white-space: nowrap;
}

.sortableHeader:hover {
    background-color: #3c3c3c;
}

.dataRow {
    transition: background-color 0.3s;
}

.dataRow:nth-child(even) {
    background-color: #242424;
}

.dataRow:hover {
    background-color: #2a2a2a;
}

.avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 0.875rem;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.statusBox {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    display: inline-block;
    min-width: 80px;
    text-transform: capitalize;
}

.statusOpen { 
    background-color: rgba(59, 130, 246, 0.2);
    color: #60a5fa; 
}

.statusSolved,
.statusClosed { 
    background-color: rgba(34, 197, 94, 0.2);
    color: #4ade80; 
}

.statusHold { 
    background-color: rgba(234, 179, 8, 0.2);
    color: #fde047; 
}

.tagContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.tag {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    white-space: nowrap;
}

.sentimentIndicator {
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.75rem;
    font-weight: 600;
    text-align: center;
    color: #000000;
}

.expandedRow {
    background-color: #1e1e1e;
}

.expandedContent {
    padding: 20px;
    border-top: 1px solid #333;
}

.ticketSummary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.ticketSummary h3 {
    font-size: 1.2rem;
    color: #ffffff;
    margin: 0;
}

.conversationContainer {
    max-height: 400px;
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 10px;
    background-color: #252525;
    border-radius: 8px;
}

.message {
    margin-bottom: 15px;
    padding: 12px;
    border-radius: 8px;
    font-size: 0.9rem;
    line-height: 1.5;
}

.message.customer {
    background-color: #2d3748;
    margin-right: 20px;
}

.message.agent {
    background-color: #3c366b;
    margin-left: 20px;
}

.messageHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.messageSender {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: #e2e8f0;
}

.messageSender svg {
    margin-right: 5px;
}

.messageTime {
    font-size: 0.75rem;
    color: #a0aec0;
}

.messageContent {
    color: #e2e8f0;
}

.replyContainer {
    background-color: #252525;
    padding: 15px;
    border-radius: 8px;
}

.replyContainer h4 {
    margin-top: 0;
    margin-bottom: 10px;
    color: #ffffff;
}

.sendReplyButton {
    margin-top: 10px;
    padding: 8px 16px;
    background-color: #4a5568;
    color: #ffffff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sendReplyButton:hover {
    background-color: #2d3748;
}

.sendReplyButton svg {
    margin-right: 8px;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    background-color: #2c2c2c;
}

.pagination button {
    background-color: #3c3c3c;
    border: none;
    color: #ffffff;
    padding: 8px 16px;
    margin: 0 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.pagination button:hover:not(:disabled) {
    background-color: #4c4c4c;
}

.pagination button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination span {
    color: #ffffff;
    font-size: 0.875rem;
}


:global(.ql-toolbar.ql-snow) {
    border: 1px solid #4a5568;
    background-color: #2d3748;
}

:global(.ql-container.ql-snow) {
    border: 1px solid #4a5568;
    background-color: #1a202c;
    color: #ffffff;
    height: 150px;
}

:global(.ql-editor p) {
    color: #ffffff;
}

:global(.ql-snow .ql-stroke) {
    stroke: #a0aec0;
}

:global(.ql-snow .ql-fill),
:global(.ql-snow .ql-stroke.ql-fill) {
    fill: #a0aec0;
}

:global(.ql-snow .ql-picker) {
    color: #a0aec0;
}

:global(.ql-snow .ql-picker-options) {
    background-color: #2d3748;
}

:global(.ql-snow .ql-picker.ql-expanded .ql-picker-label) {
    color: #e2e8f0;
}

:global(.ql-snow .ql-picker.ql-expanded .ql-picker-options) {
    border-color: #4a5568;
}

@media (max-width: 1200px) {
    .dataTable th:nth-child(9),
    .dataTable td:nth-child(9) {
        display: none;
    }
}

@media (max-width: 992px) {
    .dataTable th:nth-child(7),
    .dataTable td:nth-child(7) {
        display: none;
    }
}

@media (max-width: 768px) {
    .dataTable th,
    .dataTable td {
        padding: 12px 8px;
    }

    .dataTable th {
        font-size: 0.7rem;
    }

    .tag,
    .statusBox,
    .sentimentIndicator {
        font-size: 0.7rem;
    }

    .avatar {
        width: 30px;
        height: 30px;
        font-size: 0.75rem;
    }
}
*/